import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'resolveFullname',
    standalone: true
})
export class ResolveFullnamePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }
    if (!value.val()) {
      return '';
    }
    return value.val().firstname + ' ' + value.val().lastname;
  }
}
