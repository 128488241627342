import { Pipe, PipeTransform } from '@angular/core';

import { DataService } from '../firebase.service';

@Pipe({
    name: 'conversionExit',
    standalone: true
})
export class ConversionExitPipe implements PipeTransform {
  output: string;
  constructor(private ds: DataService) {}

  transform(value: any, object: any, returnKeyIfInvalidDocument?: boolean): any {
    // let exp = parseFloat(exponent);

    if (!value) {
      return null;
    }
    if (value === 'undefined') {
      return null;
    }
    if (value === true) {
      return null;
    }
    if (value === false) {
      return null;
    }

    if (object === 'module') {
      if (!value || value.substr(0, 1)! !== '-' || /[\.,#$\[\]]+/m.test(value)) {
        return Promise.resolve(value);
      }
    }

    if (value.indexOf('.') !== -1) {
      return null;
    }
    if (value.indexOf('#') !== -1) {
      return null;
    }
    if (value.indexOf('$') !== -1) {
      return null;
    }
    if (value.indexOf('[') !== -1) {
      return null;
    }
    if (value.indexOf(']') !== -1) {
      return null;
    }
    switch (object) {
      case 'customer':
        return this.ds
          .getCustomerList()
          .child(value)
          .once('value');
      case 'orderCategory':
        return this.ds
          .getOrderCategoryList()
          .child(value)
          .once('value');
      case 'orderType':
        return this.ds
          .getOrderTypeList()
          .child(value)
          .once('value');
      case 'purchaseOrderType':
        return this.ds
          .getPurchaseOrderTypeList()
          .child(value)
          .once('value');
      case 'language':
        return this.ds
          .getLanguageList()
          .child(value)
          .once('value');
      case 'module':
        return this.ds
          .getModuleList()
          .child(value)
          .once('value');
      case 'nationality':
        return this.ds
          .getNationalityList()
          .child(value)
          .once('value');
      case 'class':
        return this.ds
          .getClassList()
          .child(value)
          .once('value');
      case 'course':
        return this.ds
          .getCourseList()
          .child(value)
          .once('value');
      case 'residencestatus':
        return this.ds
          .getResidencestatusList()
          .child(value)
          .once('value');
      case 'dolmetscher':
        return this.ds
          .getUseraccountList()
          .child(value)
          .once('value');
      case 'supervisor':
        return this.ds
          .getUseraccountList()
          .child(value)
          .once('value');
      case 'teacher':
        return this.ds
          .getUseraccountList()
          .child(value)
          .once('value');
      case 'user':
        return this.ds
          .getUseraccountList()
          .child(value)
          .once('value');
      case 'person':
        return this.ds
          .getPersonList()
          .child(value)
          .once('value');
      case 'classperson':
        return this.ds
          .getPersonList()
          .child(value)
          .once('value');
      case 'competenceprofile':
        return null;
      case 'personcompetence':
        return this.ds
          .getPersonList()
          .child(value)
          .once('value');
      case 'waitinglist':
        return this.ds
          .getWaitinglistList()
          .child(value)
          .once('value');
      case 'useraccount':
        return this.ds
          .getUseraccountList()
          .child(value)
          .once('value');
      case 'personpresence':
        return Promise.resolve(value);
      case 'classsection':
        return Promise.resolve(value);
      default:
        return null;
    }
  }
}
