import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'resolveName',
    standalone: true
})
export class ResolveNamePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }
    if (typeof value === 'string') {
      return value;
    }
    if (!value.val()) {
      return '';
    }
    return value.val().name;
  }
}
